<template>
  <b-container class="py-5 px-4 px-md-5 container_alerts">
    <b-row>
      <b-col cols="12">
        <h2 class="fw-bold">Operators</h2>
      </b-col>
      <b-col cols="12" class="text-end">
        <b-button variant="secondary" class="mb-4" :to="{ name: 'operators.add' }">Add operator</b-button>
      </b-col>
      <b-col cols="12">
        <b-table hover :items="items" :fields="fields" :busy="load" responsive
          tbody-tr-class="transition-tr" class="table-alerts" table-variant="light">
        <template #table-busy>
          <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(status)="row">
          <b-form-checkbox v-if="row.item.status" v-model="row.item.status"></b-form-checkbox>
          <b-form-checkbox v-else v-model="row.item.status"></b-form-checkbox>
        </template>
      </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    data() {
      return {
        items: [
          { name: 'Mateo Monsalve', email: 'mateo.monsalve@dogeniuson.com', last_login: '07/01/2022', status: true, member_group: 'Administrators' },
          { name: 'Jose Llanes', email: 'jose.llanes@dogeniuson.com', last_login: '07/01/2022', status: false, member_group: 'Administrators' },
          { name: 'Yehison Gómez', email: 'yehison.gomez@dogeniuson.com', last_login: '07/01/2022', status: true, member_group: 'Administrators' },
        ],
        fields: [
          { key: 'name', label: 'Name' },
          { key: 'email', label: 'Email' },
          { key: 'last_login', label: 'Last Login' },
          { key: 'status', label: 'Status' },
          { key: 'member_group', label: 'Member of groups' },
        ],
        load: false
      }
    }
  }
</script>
